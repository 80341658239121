<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消新增?', '提示', { type: 'warning' }).then(res => $router.back())" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <div class="form-body" style="padding-top: 60rem; padding-left: 40rem">
      <el-form ref="form" :model="formData" label-width="140rem" :rules="rules">
        <el-form-item label="特批类型" prop="type">
          <el-select :popper-append-to-body="false" v-model="formData.type" placeholder="请选择">
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="学生身份证号" prop="stu_id_card">
          <div class="flex flex-align">
            <el-input v-model="formData.stu_id_card" maxlength="18" placeholder="请输入身份证号搜索"></el-input>
            <el-button :loading="searchLoading" style="margin-left: 20rem; height: 30rem; line-height: 6rem" type="primary" @click="getStudentInfo('stu_id_card')">查询 </el-button>
          </div>
        </el-form-item>
        <el-form-item label="商品订单号" prop="order_number">
          <div class="flex flex-align">
            <el-input v-model="formData.order_number" maxlength="30" placeholder="请输入商品订单号搜索"></el-input>
            <el-button :loading="searchLoading" style="margin-left: 20rem; height: 30rem; line-height: 6rem" type="primary" @click="getStudentInfo('order_number')">查询 </el-button>
          </div>
        </el-form-item>
        <el-form-item label="商品订单状态">
          <p>{{ info.pay_status }}</p>
        </el-form-item>
        <el-form-item label="钉钉审批单号" prop="approval_num">
          <el-input v-model="formData.approval_num" maxlength="30" placeholder="请输入钉钉审批单号"></el-input>
          <el-button :loading="searchLoading" style="margin-left: 20rem; height: 30rem; line-height: 6rem" type="primary" @click="getDdingInfo">查询 </el-button>
        </el-form-item>
        <el-form-item label="审批单状态">
          <p>{{ info.approval_status }}</p>
        </el-form-item>
        <template v-if="formData.type === 1">
          <el-row class="zhu-row-line">
            <el-col :span="8">
              <div class="label">应缴纳金额</div>
              <div>{{ info.privilege_before }}</div>
            </el-col>
            <el-col :span="8">
              <div class="label">减免金额</div>
              <div>{{ info.privilege_val }}</div>
            </el-col>
            <el-col :span="8">
              <div class="label">实缴金额</div>
              <div>{{ info.privilege_after }}</div>
            </el-col>
          </el-row>
        </template>
        <template v-else-if="formData.type === 2">
          <el-form-item label="分期计划" prop="privilege_val">
            <el-select :popper-append-to-body="false" v-model="formData.privilege_val" placeholder="请选择">
              <el-option v-for="item in stagesOptions" :key="item.plan_id" :label="item.plan_name" :value="item.plan_id">
            </el-option></el-select>
          </el-form-item>
        </template>

        <el-form-item ref="img-box" label="特批审批凭证" prop="approval_proof">
          <uploadimg @change="handleChange" :value="formData.approval_proof"></uploadimg>
        </el-form-item>
      </el-form>
    </div>


</edit-template>
</template>
<script>
import uploadImg from '@/components/common/upload.vue'
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  components: { uploadImg },
  data() {
    return {
      loading: false,
      searchLoading: false,
      typeOptions: [
        { label: '学费减免', value: 1 },
        { label: '分期', value: 2 }
      ],
      stagesOptions: [],
      // 表单数据
      formData: {
        order_number: '',
        stu_id_card: '',
        order_id: '',
        type: '',
        privilege_val: '',
        approval_num: '',
        approval_proof: ''
      },
      info: {
        pay_status: '-',
        approval_status: '-',
        privilege_before: '-',
        privilege_val: '-',
        privilege_after: '-'
      },

      // 表单验证规则
      rules: {
        order_number: [{ required: true, message: '请输入商品订单号', trigger: 'change' }],
        stu_id_card: [{ required: true, message: '请输入学生身份证号', trigger: 'change' }],
        type: [{ required: true, message: '请选择特批优惠类型', trigger: 'change' }],
        privilege_val: [{ required: true, message: '请选择分期计划', trigger: 'change' }],
        approval_num: [{ required: true, message: '请输入钉钉审批单号', trigger: 'change' }],
        approval_proof: [{ required: true, message: '请上传特批审批凭证', trigger: 'change' }]
      }
    }
  },
  created() {},
  methods: {
    handleChange(val) {
      this.formData.approval_proof = val
    },
    getMoneyConfig(id) {
      this.$_register('api/recruit/special-privilege/get-fenqi-goods', {
        params: {
          order_id: id,
          type: 2
        }
      }).then(res => {
        this.stagesOptions = Array.isArray(res.data) ? [] : res.data.data
      })
    },

    getStudentInfo(key) {
      const data = this.formData[key]
      const msg = key === 'stu_id_card' ? '请输入学生身份证号' : '请输入商品订单号'
      if (!data) {
        return this.$message.error(msg)
      }
      const params = {}
      params[key] = key === 'stu_id_card' ? this.$verify.sliceIdCard(this.formData.stu_id_card) : data
      this.searchLoading = true
      this.info.pay_status = '-'
      this.$_register('api/recruit/special-privilege/get-stu-order', { params })
        .then(res => {
          const {
            data: { status, data }
          } = res
          if (status) return
          if (Array.isArray(data)) {
            return this.$message.error('未查找到此学费减免审批单')
          }
          this.formData.stu_id_card = data.student_name
          this.formData.order_number = data.order_number
          this.formData.order_id = data.order_id
          this.info.pay_status = data.pay_status
          this.getMoneyConfig(data.order_id)
        })
        .finally(() => {
          this.searchLoading = false
        })
    },
    getDdingInfo() {
      if (!this.formData.approval_num) {
        return this.$message.error('请输入钉钉审批单号')
      }
      this.searchLoading = true
      Object.keys(this.info).forEach(item => {
        if (item !== 'pay_status') {
          this.info[item] = '-'
        }
      })
      this.$_register('api/recruit/special-privilege/get-dding-approval', { params: { approval_num: this.formData.approval_num } })
        .then(res => {
          const {
            data: { status, data }
          } = res
          if (status) return
          if (Array.isArray(data)) {
            return this.$message.error('该钉钉号下暂无任何信息，请重新输入钉钉号查询')
          }
          this.info.approval_status = data.approval_status
          this.info.privilege_before = data.privilege_before
          this.info.privilege_val = data.privilege_val
          this.info.privilege_after = data.privilege_after
        })
        .finally(() => {
          this.searchLoading = false
        })
    },

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('提交后，此学生将会按照特批费用收费', {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (!this.formData.order_id) {
              return this.$message.warning('请先查询学生身份证号或商品订单号')
            }
            // this.loading = true
            let params = {
              order_id: this.formData.order_id,
              type: this.formData.type,
              approval_num: this.formData.approval_num,
              approval_proof: this.formData.approval_proof
            }
            if (this.formData.type === 2) {
              params.privilege_val = this.formData.privilege_val
            }
            this.$_register
              .post('api/recruit/special-privilege/add', { ...params })
              .then(res => {
                if (res.data.status === 0) {
                  this.$message.success({
                    message: '新增成功',
                    onClose: () => {
                      this.$store.commit('setPage', 1)
                      this.$router.go(-1)
                    }
                  })
                }
              })
              .finally(() => {
                this.loading = false
              })
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: 250rem !important;
}

::v-deep .is-uploaded .el-upload {
  display: none;
}

.replace-img {
  width: 100rem;
  margin-left: 24rem;
  border: 1rem solid #999999;
  color: #666;
  border-radius: 6rem;
  height: 30rem;
  line-height: 30rem;
  text-align: center;
  cursor: pointer;
}
.zhu-row-line {
  margin-bottom: 22rem;
  font-size: 14rem;
  line-height: 40rem;
  .label {
    width: 140rem;
    padding-right: 12rem;
    text-align: right;
    vertical-align: middle;
    float: left;
    color: #666;
  }
}
</style>
